<template>
    <div v-if="isLoading">
        Loading 
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden"></span>
        </div>
    </div>
    <ODataLookup v-else 
                    :data-object="dsConfiguredLkp" 
                    class="form-select form-select-sm" 
                    :value="config.Value" 
                    :multiselect="false" 
                    :bind="sel => { $emit('callbackFunction', sel, config) }">
        <template v-for="column in lookupFieldList" :key="column.name">
            <OColumn :colId="column.name" :width="column.name.includes('ID') ? 100 : 200" :headerName="column.name"></OColumn>
        </template>
    </ODataLookup>
</template>

<script setup>
    import { ref, onMounted, computed } from "vue";
    import { getOrCreateDataObject } from "o365-dataobject";
    // config and appID gets datasource data from the CT article and creates the datasource from that to render lookup
    const props = defineProps({
        appID: {
            Type: String,
            Required: false
        },
        config: {
            Type: String,
            Default: null,
            Required: false
        }
    });

    const isLoading = ref(true)

    const dsConfiguredLkpDatasources = getOrCreateDataObject({ 
        id: 'dsConfiguredLkpDatasources' + crypto.randomUUID(),
        viewName: 'stbv_WebSiteCMS_Datasources',
        maxRecords: 20,
        whereClause: "",
        selectFirstRowOnLoad: true,
        loadRecents: false,
        distinctRows: true,
        fields: [
            { name: "ArticleID", type: "string" },
            { name: "ID", type: "string" },
            { name: "ViewName", type: "string" }
        ]
    });

    const dsConfiguredLkpDatasourceFields = getOrCreateDataObject({
        id: 'dsConfiguredLkpDatasourceFields' + crypto.randomUUID(),
        viewName: 'sviw_WebSiteCMS_DatasourcesAllFields',
        maxRecords: 50,
        whereClause: "",
        selectFirstRowOnLoad: true,
        loadRecents: false,
        distinctRows: true,
        fields: [
            { name: "ArticleID", type: "string" },
            { name: "DatasourceID", type: "string" },
            { name: "FieldName", type: "string" },
            { name: "DataType", type: "string" }
        ]
    });

    var dsConfiguredLkp = {};
    const lookupFieldList = ref([]);

    function getLookupConfig(){
        if(!props.config.DsFields){
            return;
        }

        let dataObjectFields = props.config.DsFields.split(",");
        dataObjectFields.forEach(function(i, index){
            dataObjectFields[index] = "'"+i+"'";
        });

        dsConfiguredLkpDatasources.recordSource.whereClause = "ArticleID = '" + props.appID + "' AND ID = '" + props.config.DataSource + "'";
        dsConfiguredLkpDatasourceFields.recordSource.whereClause = "ArticleID = '" + props.appID + "' AND DatasourceID = '" + props.config.DataSource + "' AND FieldName IN (" + dataObjectFields.join(",") + ")";

        dsConfiguredLkpDatasourceFields.load().then(()=>{
            dsConfiguredLkpDatasources.load().then(async ()=>{
                lookupFieldList.value = await getLookupFields(dsConfiguredLkpDatasourceFields.data);
                dsConfiguredLkp = getOrCreateDataObject({
                    id: 'dsConfiguredLkp' + crypto.randomUUID(),
                    viewName: dsConfiguredLkpDatasources.current.ViewName,
                    maxRecords: 100,
                    whereClause: "",
                    selectFirstRowOnLoad: true,
                    loadRecents: false,
                    distinctRows: true,
                    fields: lookupFieldList.value
                });
                
                dsConfiguredLkp.load().then(()=>{
                    isLoading.value = false;                    
                });
            });
        });
    }

    async function getLookupFields(fieldList){
        let tempList = [];
        if(fieldList.length > 0){
            fieldList.forEach((i)=>{
                let t;
                if(i.DataType == "nvarchar"){
                    t = "string";
                }else if(i.DataType == "int"){
                    t = "number";
                }else if(i.DataType == "bit"){
                    t = "boolean";
                }else{
                    t = "string";
                }
                tempList.push({ name: i.FieldName, type: t});
            });
            return tempList;
        }else{
            return [{ name: "ID", type: "number" }];
        }
    }

    onMounted(()=>{
        getLookupConfig();
    });
</script>